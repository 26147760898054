span.mention {
    padding: 4px;
    background-color: #efefef;
    border-radius: 5px;
    font-weight: 500;
    line-height: 30px;
}
ul.ql-mention-list {
    background-color: #efefef;
    margin-left: 0;
    padding-left: 0;
    padding: 0;
    position: relative;
    z-index: 1;
    border: 1px dashed #3f51b5;
    border-radius: 5px;
}
ul.ql-mention-list li {
    list-style: none;
    border-bottom: 1px solid #cfcfcf;
    padding: 5px;
}
ul.ql-mention-list li.selected {
    background-color: #3f51b5;
    color: #fff;
}
ul.ql-mention-list li:last-child {
    border: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
ul.ql-mention-list li:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}